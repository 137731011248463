<ng-template [ngIf]="viewPage - 1 === 0">
  {{ index + 1 }}
</ng-template>

<ng-template [ngIf]="viewPage - 1 > 0 && index + 1 < 10">
  {{ viewPage - 1 }}{{ index + 1 }}
</ng-template>

<ng-template [ngIf]="viewPage - 1 > 0 && index + 1 === 10">
  {{ viewPage * (index + 1) }}
</ng-template>
