import { Component, ViewEncapsulation, Input } from '@angular/core';
import { IdentityAdminService } from "../../views/settings/identity-admin.service";

@Component({
  selector: 'app-preferences-view',
  templateUrl: './preferences-view.component.html',
  styleUrls: ['./preferences-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PreferencesViewComponent {
  @Input() class: string = '';
  data$: any;

  pref: any;

  constructor(private identityService: IdentityAdminService) {}

  ngOnInit(): void {
    this.data$ = this.identityService.data$.subscribe((data) => {
      if (Object.keys(data).length) {
        this.pref = {
          language: JSON.parse(<any>localStorage.getItem('language')),
          partner: JSON.parse(<any>localStorage.getItem('partner')),
          tenant: JSON.parse(<any>localStorage.getItem('tenant')),
          branch: JSON.parse(<any>localStorage.getItem('branch')),
          requester: JSON.parse(<any>localStorage.getItem('requester')),
          environment: JSON.parse(<any>localStorage.getItem('environment')),
        };
      }
    });
  }


  ngOnDestroy(): void {
    this.data$?.unsubscribe();
  }
}
