import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

import { IdentityAdminService } from "../../../../views/settings/identity-admin.service";

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss']
})
export class LanguageDropdownComponent {
  data$: any;

  selectedLanguage: any;
  languages: any = [];

  constructor(
    private translateService: TranslateService,
    private identityService: IdentityAdminService
  ){}

  ngOnInit(): void {
    this.data$ = this.identityService.data$.subscribe((data) => {
      if (Object.keys(data).length) {
        this.languages = data?.languages;
        this.selectedLanguage = this.identityService.getDropdownSelection("selectedLanguage");
      }
    });
  }

  languageChange(selectedLanguage) {
    if (!this.languages?.length) {
      return;
    }

    const languageObj = this.languages?.find(obj => obj.id === selectedLanguage);
    let { id, name, description } = languageObj;

    this.identityService.saveDropdownSelection('language', { id, name, description });
    this.identityService.saveDropdownSelection('selectedLanguage', id);

    // tell i18n service to start using current language in website
    const languageCode = languageObj?.id;
    this.translateService.use(languageCode);
  }

  ngOnDestroy(): void {
    this.data$?.unsubscribe();
  }
}
