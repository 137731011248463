import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-svg-icons',
  templateUrl: './svg-icons.component.html',
})
export class SvgIconsComponent implements OnInit {
  @Input() icon: string | undefined;
  constructor() {}

  ngOnInit(): void {
    // check if the icon type is logo
    switch (this.icon) {
      case 'logo': {
        console.log('TenantLogo:', environment.TenantLogo);
        // for setting up application tenant logo on run time
        if (environment.TenantLogo === '66N') {
          this.icon = '66N-logo';
        } else if (environment.TenantLogo === 'Rarik') {
          this.icon = `Rarik-logo`;
        } else {
          this.icon = '66N-logo';
        }
        break;
      }
      default: {
      }
    }
  }
}
