const BasePathForReplicationWebConst = '//app-staging.synia.is/replication';

export const environment = {
  staging: true,
  production: false,
  BasePathForReplicationWebapp: BasePathForReplicationWebConst.trim().length < 1 || BasePathForReplicationWebConst.startsWith('#{') ?  window.location.origin: 'https:' + BasePathForReplicationWebConst,
  ExternalConnectionAppId: '2c7b694b-1ec6-43ce-8634-30d7c0937006',
  // xApiKey: '278B995C-CA52-4D03-8BBB-BC62EEFA0FFD',
  GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  ReadBaseURL: 'https://app-staging.synia.is/replication/read/api/v1/',
  WriteBaseUrl: 'https://app-staging.synia.is/replication/write/api/v1/',
  IdentityAdminUrl: 'https://app-staging.synia.is/identity/admin-api/api/',
  IdentityUrl:'https://identity-sts.identity.illumato.com',
  TenantLogo: '66N',
  HealthLogService: 'https://health-log.staging.illumato.com/api/v1/',
  TranslationService: '',
};
