<section class="db-preferences">
  <div class="badge rounded-pill fs-6 p-1">
    <div class="d-flex flex-wrap justify-content-start justify-content-md-between gap-2">
      <div class="d-flex align-items-center px-1 px-md-1 px-lg-3 pref-icon-box py-1 animate__animated animate__fadeInRight {{class}}" title="Partner">
        <span><i class="fa-solid fa-hat-cowboy"></i></span><div class="d-flex d-md-none d-lg-flex"> {{pref?.partner?.name}}</div>
      </div>
      <div class="d-flex align-items-center px-1 px-md-1 px-lg-3 pref-icon-box py-1 animate__animated animate__fadeInRight {{class}}" title="Tenant">
        <span><i class="bi bi-buildings-fill"></i></span><div class="d-flex d-md-none d-lg-flex"> {{pref?.tenant?.name}}</div>
      </div>
      <div class="d-flex align-items-center px-1 px-md-1 px-lg-3 pref-icon-box py-1 animate__animated animate__fadeInRight {{class}}" title="Environment">
        <span><i class="bi bi-git"></i></span> <div class="d-flex d-md-none d-lg-flex"> {{pref?.environment?.name}}</div>
      </div>
    </div>
  </div>
</section>
