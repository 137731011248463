<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <span
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-0 nav-hamburger-icon me-3"
    >
      <app-svg-icons icon="hamburger"> </app-svg-icons>
    </span>

    <!--<button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
    </button>-->

    <c-header-nav class="d-none d-lg-flex me-auto">
      <div>
        <div class="small">
          <span class="cursor text-capitalize fw-bold text-decoration-underline" (click)="logout()">logout</span>
          <span class="username animate__animated animate__fadeIn"> ({{user?.profile?.name || 'Anonymous'}}) </span>
        </div>
        <div class="app-title">
          <h5 class="text-capitalize mb-0">{{navbarTitle}}</h5>
        </div>
      </div>

      <!--
      <c-nav-item>
        <a cNavLink routerLink="/dashboard" routerLinkActive="active">
          Dashboard
        </a>
      </c-nav-item>
      -->
    </c-header-nav>

    <c-header-nav class="d-flex align-items-center justify-content-end flex-wrap flex-md-wrap-reverse">
      <c-nav-item class="mx-0 mx-md-3">
        <app-preferences-view></app-preferences-view>
      </c-nav-item>
      <div class="headerWidgets d-flex align-items-center flex-wrap">
        <c-nav-item>
          <app-language-dropdown></app-language-dropdown>
        </c-nav-item>

        <c-nav-item>
          <span class="border bg-gray border-end rounded-5 mx-3"></span>
        </c-nav-item>

        <c-nav-item>
          <app-user-profile-dropdown></app-user-profile-dropdown>
        </c-nav-item>

        <c-nav-item>
          <span class="border bg-gray border-end rounded-5 mx-3"></span>
        </c-nav-item>

        <!--<c-nav-item class="ms-4">
          <span class="border bg-gray border-end rounded-5 me-3"></span>
          <span class="gray fw-bold">{{'Welcome' |lowercase|translate:{user_name: user?.profile?.name?.split(' ')[0] || '...'} }}</span>
          <span class="border bg-gray border-end rounded-5 ms-3"></span>
        </c-nav-item>-->

        <c-nav-item>
          <div>
            <div (click)="brandingService.setDarkTheme()" *ngIf="theme.name === 'light'">
              <i class="bi bi-brightness-high fs-3"></i>
            </div>
            <div (click)="brandingService.setLightTheme()" *ngIf="theme.name === 'dark'">
              <i class="bi bi-brightness-high-fill fs-3"></i>
            </div>
          </div>
        </c-nav-item>
      </div>
    </c-header-nav>
  </c-container>
</ng-container>
<!--</c-header>-->

