import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService as ToasterService } from 'ngx-toastr';

import { environment } from '../../../environments/environment';
import { Log } from 'oidc-client';
import axios from 'axios';
// Log.logger = console;

import { UserManager, User, UserManagerSettings } from 'oidc-client';
import { Subject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OidcAuthService {
  private config: UserManagerSettings = {
    authority: environment.IdentityUrl,
    client_id: 'replication_client',
    client_secret: 'replication_secret',

    redirect_uri: environment.BasePathForReplicationWebapp + '/#/signing-in#',
    post_logout_redirect_uri: environment.BasePathForReplicationWebapp + '/#/signing-out',
    response_type: 'id_token token' /* token id_token code */,
    scope:
      'integrationservice_read openid profile email skoruba_identity_admin_api roles offline_access replication_configurationservice_write replication_configurationservice_read healthlogservice_read',
    /*response_mode: 'fragment',*/

    filterProtocolClaims: true,
    loadUserInfo: true,
    // automaticSilentRenew: true,
  };

  private _userManager = new UserManager(this.config);
  private _user: User | any = null;
  private _loginChangedSubject = new Subject<boolean>();

  public loginChanged = this._loginChangedSubject.asObservable();

  constructor(
    private router: Router,
    private toaster: ToasterService,
    private ngxService: NgxUiLoaderService
  ) {}

  public login = () => {
    return this._userManager.signinRedirect();
  };

  public getUser = () => {
    return this._user;
  };

  public isAuthenticated = (): Promise<boolean> => {
    return this._userManager.getUser().then((user) => {
      if (this._user !== user) {
        this._loginChangedSubject.next(this.checkUser(user));
      }
      this._user = user;
      localStorage.setItem('user', JSON.stringify(this._user));
      return this.checkUser(user);
    });
  };

  checkUser(user?): boolean {
    if (!user) user = this._user;
    return !!user && !user.expired;
  }

  public finishLogin = (): Promise<User> => {
    return this._userManager.signinRedirectCallback().then((user) => {
      this._user = user;
      this._loginChangedSubject.next(this.checkUser(user));
      return user;
    });
  };

  public logoutWithoutRedirect = async () => {
    const req = await this._userManager.createSignoutRequest();
    const res = await axios.get(req.url);
    console.log(res);
    this.router.navigate(['/login']);
  };

  public logout = () => {
    return this._userManager.signoutRedirect();
  };

  public finishLogout = () => {
    return this._userManager.signoutRedirectCallback().then((res) => {
      this._user = null;
      this._loginChangedSubject.next(false);
    });
  };

  getAuthorizationHeaderValue(): string {
    return `Bearer ${this._user.id_token}`;
  }

  
  getBearer(): string {
    return `Bearer ${this._user.access_token}`;
  }

  public refreshToken = async () => {
    try {
      this._user = await this._userManager.signinSilent();
    } catch (e) {
      console.info(`Silent Token Updated (with some issues)`);
    }
  };
}
