export default {
  light: {
    name: 'light',
    logoUrl: '',
    footer: {
      TagLine1: 'Keeping Health',
      TagLine2: 'All right reserved',
    },
    properties: {
      '--header-background-color': '#FFFFFF',
      '--header-font-color': '#111111',

      '--sidebar-background-color': '#16487a',
      '--sidebar-font-color': '#AAAAAA',

      '--banner-background-color': '#001f3f',
      '--banner-font-color': '#FFFFFF',
    },
  },
  dark: {
    name: 'dark',
    logoUrl: '',
    footer: {
      TagLine1: 'Keeping Health',
      TagLine2: 'All right reserved',
    },
    properties: {
      '--header-background-color': '#FFFFFF',
      '--header-font-color': '#111111',

      '--sidebar-background-color': '#16487a',
      '--sidebar-font-color': '#AAAAAA',

      '--banner-background-color': '#001f3f',
      '--banner-font-color': '#FFFFFF',
    },
  },
};
