import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByKeyAndValue',
  pure: false,
})
export class FilterByKeyAndValue implements PipeTransform {
  transform(list: any[], filter: any): any {
    if (
      !list ||
      !filter ||
      !Object.keys(filter).length ||
      !filter.key ||
      !filter.value
    )
      return list;

    const { key, value } = filter;
    return list.filter((item) => {
      const itemValue = item[key];
      return itemValue.toLocaleLowerCase().includes(value.toLocaleLowerCase());
    });
  }
}
