<!-- <app-login-svg></app-login-svg> -->

<div class="landing-page-container" [hidden]="true"> <!-- not needed login screen (dure) -->
    <div class="landing-page-wrapper">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12">
                    <div class="logo-and-login-btn-container">
                        <div class="logo-and-login-btn-wrapper">
                            <div class="logo animate__animated animate__fadeInRight">
                              <!--<img class="me-1" src="assets/img/ids.png" height="50"/>-->
                              <img src="assets/img/brand/full.png" />
                            </div>

                            <div class="login-btn-wrap ">
                                <button type="button" class="btn btn-light fw-bold text-primary"
                                    (click)="login()">
                                  <img class="me-1" src="assets/img/ids.png" height="20"/> Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <!-- <img src="assets/landing-page-image.png" /> -->
                    <div class="landing-page-artwork-wrapper">
                        <div class="text-wrap">
                            <h3 class="animate__animated animate__fadeInUp text-shadow">Database Replication</h3>
                            <h2 class="animate__animated animate__fadeInUp animate__slow text-shadow">Configuration</h2>
                        </div>
                        <app-login-svg class="ms-5 ps-5 magictime vanishIn"></app-login-svg>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="footer-logos-container">
                        <div class="footer-logos-wrapper">
                            <div class="left-logo animate__animated animate__fadeInRight">
                                <img src="assets/img/logo.png" />
                            </div>
                            <div class="right-logo animate__animated animate__fadeInLeft">
                                <!-- <app-svg-icons icon="main-logo"></app-svg-icons>-->
                                <!-- <app-svg-icons icon="logo"> </app-svg-icons> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
