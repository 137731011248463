import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgInitDirective } from './ng-init.directive';
import { VariableDirective } from './variable.directive';
import { BackDirective } from './back.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [NgInitDirective, VariableDirective, BackDirective],
  exports: [NgInitDirective, VariableDirective, BackDirective],
})
export class DirectiveModule {}
