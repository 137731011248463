import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { OidcAuthService } from '../core/oidc/oidc-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private oidcService: OidcAuthService) {}

  async canActivate(): Promise<boolean> {
    const authenticated = await this.oidcService.isAuthenticated();
    if (!authenticated) {
      console.log('auth guard: not loggedIn');
      this.router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }
}
