import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'reset-back-to-model',
  templateUrl: './reset-back-to-model.component.html',
  styleUrls: ['./reset-back-to-model.component.scss'],
})
export class ResetBackToModel implements OnInit {
  @Input() resetType;
  @Input() selectedItem;

  loading: boolean = false;
  model: any = {};

  constructor(public activeModal: NgbActiveModal) {}

  async ngOnInit(): Promise<void> {}

  async submitModalForm(modalForm) {

    const payload = {
      ...modalForm.value,
    };
  

    if (modalForm.valid) {
      this.loading = true;
      try {
        modalForm.reset();
        this.activeModal.close(payload);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    }
  }
}
