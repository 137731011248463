export enum EEntityStates {
  Create = 0,
  Update,
  Delete,
  UpdatePartial,

}

export enum Types {
  Rest = 0,
  Bus,
}

export enum QueryFilterMode {
  And,
  Or,
}

export enum QueryFilterOperator {
  Equals,
  NotEquals,
  GreaterThan,
  GreaterThanEqualsTo,
  LessThan,
  LessThanEqualsTo,
  StartsWith,
  EndsWith,
  Contains,
  In,
}

export enum QueryFilterType {
  Text,
  Number,
  Date,
  Boolean,
}

export enum In {
  Logs,
  CentralDatabase,
}

export enum SortMode
{
    Ascending = 1,
    Descending = -1
}