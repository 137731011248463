import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    title: true,
    name: 'Menu',
  },

  /*
  {
    name: 'Colors',
    url: '/theme/colors',
    iconComponent: { name: 'cil-drop' },
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },

  {
    name: 'Menu',
    url: '/url',
    iconComponent: { name: 'cil-cursor' },
    children: [
      {
        name: 'Menu 1',
        url: '/folder/component-name',
      },
    ],
  },
  */

  {
    name: 'Dashboard',
    icon: 'fa-solid fa-gauge-high',
    url: '/dashboard/home',
    // iconComponent: { classList: 'fa-solid fa-gauge-high' },
  },

  {
    name: 'Database',
    url: '/dashboard/database',
    icon: 'fa-solid fa-database',
  },
  /*{
    name: 'Environment',
    url: '/dashboard/environment',
    icon: 'fa-solid fa-server',
  },*/
  {
    name: 'Configuration',
    url: '/dashboard/configuration',
    icon: 'fa-solid fa-gear',
  },
  {
    name: 'Desktop Machine',
    url: '/dashboard/desktop-machine/',
    icon: 'fa-solid fa-desktop',
  },
  {
    name: 'Download',
    url: '/dashboard/download',
    icon: 'fa-solid fa-download',
  },
  {
    name: 'Health-logs',
    url: '/dashboard/health-logs',
    icon: 'fa-solid fa-square-h',
  },
];
