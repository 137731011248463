<div class="landing-page-container">
  <div class="landing-page-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 text-center">
          <!-- <img src="assets/landing-page-image.png" /> -->
          <div class="landing-page-artwork-wrapper">
            <div class="text-wrap">
              <h3 class="animate__animated animate__fadeInUp text-shadow">Database Replication</h3>
              <h2 class="animate__animated animate__fadeInUp animate__slow text-shadow">Configuration</h2>
            </div>
            <app-login-svg class="ms-5 ps-5 animate__animated animate__fadeIn"></app-login-svg>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="footer-logos-container">
            <div class="footer-logos-wrapper">
              <div class="mx-auto text-white">
                Please wait..
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
