import {
  Directive,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';

@Directive({
  selector: '[ngInit]',
})
export class NgInitDirective implements OnInit, AfterViewInit {
  @Output()
  ngInit: EventEmitter<any> = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.ngInit.emit();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
