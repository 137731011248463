import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
  AvatarModule,
  BadgeModule,
  BreadcrumbModule,
  DropdownModule,
  GridModule,
  HeaderModule,
  NavModule,
  SharedModule,
  SidebarModule,
} from '@coreui/angular';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconModule, IconSetService } from '@coreui/icons-angular';

import { HttpClientModule } from '@angular/common/http';

// Import necessary core modules
import { CoreModule } from './core/core.module';

// Import initial app component
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Import containers
import {
  DefaultFooterComponent,
  LanguageDropdownComponent,
  UserProfileDropdownComponent,
  DefaultHeaderComponent,
  DefaultLayoutComponent,
} from './containers';
const APP_CONTAINERS = [
  DefaultFooterComponent,
  LanguageDropdownComponent,
  UserProfileDropdownComponent,
  DefaultHeaderComponent,
  DefaultLayoutComponent,
];

import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderConfig, NgxUiLoaderModule, SPINNER } from 'ngx-ui-loader';
import { ColumnDropdownComponent } from './column-dropdown/column-dropdown.component';
import { ListComponentComponent } from './list-component/list-component.component';

const ngxUiDefaultLoaderConfig: NgxUiLoaderConfig = {
  fastFadeOut: true,
  overlayColor: 'transparent', // 'rgba(0, 0, 0, 0.3)',
  fgsColor: '#9b9d9e',
  pbColor: '#16487a',
  bgsOpacity: 0.5,
  delay: 0,
  fgsType: SPINNER.ballScaleMultiple, // foreground spinner type
  bgsType: SPINNER.ballScaleMultiple, // background spinner type
};

declare global {
  interface self {
    // ⚠️ notice that "Window" is capitalized here
    self: Window & typeof globalThis;
  }
}

@NgModule({
  declarations: [AppComponent, ...APP_CONTAINERS, ColumnDropdownComponent, ListComponentComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    IconModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    NgxUiLoaderModule.forRoot(ngxUiDefaultLoaderConfig),

    HttpClientModule,

    AvatarModule,
    BadgeModule,
    BreadcrumbModule,
    // ButtonGroupModule,
    // ButtonModule,
    // CardModule,
    DropdownModule,
    // FooterModule,
    // FormModule,
    GridModule,
    HeaderModule,
    // ListGroupModule,
    NavModule,
    // ProgressModule,
    SharedModule,
    SidebarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(), // true
      // register ServiceWorker as soon as application is stable
      registrationStrategy: 'registerWhenStable:3000', // or after 3 seconds (whichever comes first)
    }),
    // TabsModule,
    // UtilitiesModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    IconSetService,
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
