import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeAgo',
  pure: false,
})
export class timeAgo implements PipeTransform {
  transform(dateTime: string): any {
    return moment.utc(dateTime).fromNow();
  }
}
