import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-svg',
  templateUrl: './login-svg.component.html',
  styleUrls: ['./login-svg.component.scss'],
})
export class LoginSvgComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
