import { Component, OnInit } from '@angular/core';
import {
  NavigationEnd,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ToastrService as ToasterService } from 'ngx-toastr';
import { OidcAuthService } from './core/oidc/oidc-auth.service';

import { Title } from '@angular/platform-browser';
import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from '../icons/icon-subset';

import { environment } from '../environments/environment';
import { BrandingService } from './views/settings/branding/branding.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body',
  templateUrl: './app.component.html',
  // template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'Database Replication Configuration';

  constructor(
    private oidcService: OidcAuthService,
    private toaster: ToasterService,
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private ngxService: NgxUiLoaderService,
    private brandingService: BrandingService,
  ) {
    titleService.setTitle(this.title);
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }

  async ngOnInit() {
    console.log('environment', environment);

    this.brandingService.setLightTheme();
    // this.brandingService.setDarkTheme();

    this.router.events.subscribe((evt: any) => {
      switch (true) {
        case evt instanceof RouteConfigLoadStart: {
          this.ngxService.start();
          break;
        }
        case evt instanceof RouteConfigLoadEnd: {
          this.ngxService.stop();
          break;
        }
      }

      if (evt instanceof NavigationEnd) {
        if (
          this.oidcService.checkUser() &&
          evt.url !== '/dashboard/settings/preferences'
        ) {
          setTimeout(() => this.checkMissingPreferences(), 1000);
        }
      }
    });
  }

  checkMissingPreferences() {
    // const missingPreferences = this.ps.missingPreferences();
    // if (missingPreferences?.length) {
    //   // this.router.navigate(['dashboard/settings/preferences']);
    // }
  }
}
