import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-indexing',
  templateUrl: './table-indexing.component.html',
  styleUrls: ['./table-indexing.component.scss'],
})
export class TableIndexingComponent implements OnInit {
  @Input() index: any | undefined;
  @Input() viewPage: any | undefined;
  constructor() {}

  ngOnInit(): void {}
}
