import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { OidcAuthService } from '../oidc-auth.service';

@Component({
  selector: 'app-signing-out-redirect',
  templateUrl: './signing-out-redirect.component.html',
})
export class SigningOutRedirectComponent implements OnInit {
  constructor(private oidcService: OidcAuthService, private router: Router) {}

  ngOnInit(): void {
    this.oidcService.finishLogout().then((_) => {
      this.router.navigate(['/login']);
    });
  }
}
