import { Injectable } from '@angular/core';

import {
  TranslateLoader,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';

import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToastrService as ToasterService } from 'ngx-toastr';
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class I18nService implements TranslateLoader, MissingTranslationHandler {
  constructor(private toaster: ToasterService) {}
  app = 'replication';

  // @ts-ignore
  handle(params: MissingTranslationHandlerParams) {
    if (params?.key) {
      return this.app + '_' + params.key;
    }
  }

  getTranslation(lang: string): Observable<any> {
    console.log('language:', lang);
    let promise: any;

    try {
      promise = axios.get(`translation?languagelabel=${lang}`, {
        headers: {
          hasLoading: false,
        },
      });
    } catch (e) {
      this.toaster.error(`Couldn't load translation`, 'Reload the page');
      console.log('Error loading translation', e);
      return of([]); // empty in case of error
    }

    const observable = from(promise);
    return observable.pipe(
      map((res: any) => {
        return res || [];
      })
    );
    // return Observable.from(promise);
  }
}
