<ng-select
  [name]="name"
  appendTo="#ng-select-dropdown"
  class="multi-select-wrapper"
  [placeholder]="placeholder"
  [items]="items"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [multiple]="true"
  [closeOnSelect]="false"
  [(ngModel)]="ngModel"
  (ngModelChange)="modelChange()">
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice:0:2; let i=index">
      <span class="ng-value-label">{{item[bindLabel]}}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 2">
      <span class="ng-value-label">{{items.length - 2}} more...</span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
    <input [id]="'item-'+index" type="checkbox" [ngModel]="item$.selected"/>
    <span class="ms-2" [ngOptionHighlight]="search">{{item[bindLabel]}}</span>
  </ng-template>
</ng-select>

<div id="ng-select-dropdown" class="position-relative"></div>
