import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class MultiselectComponent implements OnInit {
  @Input() name: any | undefined;
  @Input() placeholder: any | undefined;
  @Input() items: any | undefined;
  @Input() bindLabel: any | undefined;
  @Input() bindValue: any | undefined;
  @Input() ngModel: any | undefined;

  @Output() newValues = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    // console.log('placeholder', this.placeholder);
    // console.log('items', this.items);
    // console.log('bindLabel', this.bindLabel);
    // console.log('bindValue', this.bindValue);
    // console.log('ngModel', this.ngModel);
  }

  modelChange() {
    this.newValues.emit(this.ngModel);
  }
}
