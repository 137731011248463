<div class="w-100" title="{{selectedLanguage ? 'Site Language ' + selectedLanguage : ''}}">
  <ng-select (change)="languageChange(selectedLanguage)"
             [(ngModel)]="selectedLanguage"
             [clearable]="false"
             [disabled]="!languages?.length"
             [items]="languages" bindLabel="description" bindValue="id"
             [placeholder]="'Site Language'"
             [searchable]="true"
             class="header-dropdown animate__animated animate__headShake animate__delay-1s"
             name="language">
    <ng-template let-item="item" ng-label-tmp>
      <div class="d-flex align-items-center justify-content-center">
        <img [src]="item?.logo" class="mx-2" width="20" />
        <!--<i class="fa fa-language fa-lg mx-2"></i>-->
        <span class="ms-2 ng-value-label">{{item.description}}</span>
      </div>
    </ng-template>
    <ng-template let-index="index" let-item="item" let-item$="item$" let-search="searchTerm" ng-option-tmp>
      <div [ngClass]="{'selected active fw-bold': selectedLanguage === item.id}">
        <img [src]="item?.logo" class="mx-2" width="20" />
        <span [ngOptionHighlight]="search" class="ms-2">{{item.description}}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
