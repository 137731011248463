import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { UnAuthGuard } from './services/unauth.guard';

import { SigningInRedirectComponent } from './core/oidc/signing-in-redirect/signing-in-redirect.component';
import { SigningOutRedirectComponent } from './core/oidc/signing-out-redirect/signing-out-redirect.component';

import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './core/oidc/login/login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/home',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        data: { title: `Dashboard` },
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'database',
        data: { title: `Database` },
        loadChildren: () =>
          import('./views/database/database.module').then(
            (m) => m.DatabaseModule
          ),
      },
      {
        path: 'environment',
        data: { title: `Environment` },
        loadChildren: () =>
          import('./views/environment/environment.module').then(
            (m) => m.EnvironmentModule
          ),
      },
      {
        path: 'configuration',
        data: { title: `Configuration` },
        loadChildren: () =>
          import('./views/configuration/configuration.module').then(
            (m) => m.ConfigurationModule
          ),
      },

      {
        path: 'desktop-machine',
        data: { title: `Desktop Machine` },
        loadChildren: () =>
          import('./views/desktop-machine/desktop-machine.module').then(
            (m) => m.DesktopMachineModule
          ),
      },
      {
        path: 'download',
        data: { title: `Download` },
        loadChildren: () =>
          import('./views/download/download.module').then(
            (m) => m.DownloadModule
          ),
      },
      {
        path: 'trash',
        data: { title: `Trash` },
        loadChildren: () =>
          import('./views/trash/trash.module').then((m) => m.TrashModule),
      },
      {
        path: 'settings',
        data: { title: `Settings` },
        loadChildren: () =>
          import('./views/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: 'health-logs',
        data: { title: `Health logs` },
        loadChildren: () =>
          import('./views/health-logs/health-logs.module').then(
            (m) => m.HealthLogsModule
          ),
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UnAuthGuard],
    data: {
      title: 'Login Page',
    },
  },
  { path: 'signing-in', component: SigningInRedirectComponent },
  { path: 'signing-out', component: SigningOutRedirectComponent },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
      // relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
