import { Component } from '@angular/core';

@Component({
  selector: 'app-column-dropdown',
  templateUrl: './column-dropdown.component.html',
  styleUrls: ['./column-dropdown.component.scss']
})
export class ColumnDropdownComponent {

}
