import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterByEnv } from './filter-by-env.pipe';
import { FilterByKeyAndValue } from './filter-by-key-value.pipe';
import { TypeofPipe } from './typeOfPipe';
import { PrettyJsonPipe } from './prettyjson';
import { timeAgo } from './time-ago.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FilterByEnv,
    FilterByKeyAndValue,
    TypeofPipe,
    PrettyJsonPipe,
    timeAgo,
  ],
  exports: [
    FilterByEnv,
    FilterByKeyAndValue,
    TypeofPipe,
    PrettyJsonPipe,
    timeAgo,
  ],
})
export class PipesModule {}
