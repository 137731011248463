import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { OidcAuthService } from '../oidc-auth.service';

@Component({
  selector: 'app-signing-in-redirect',
  templateUrl: './signing-in-redirect.component.html',
  styleUrls: ['./signing-in-redirect.component.scss'],
})
export class SigningInRedirectComponent implements OnInit {
  constructor(private oidcService: OidcAuthService, private router: Router) {}

  async ngOnInit() {
    this.oidcService.finishLogin().then(async (user) => {
      // const { sub } = user.profile;
      this.router.navigate(['/']);
    });
  }
}
