import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibFacebook,
  cibGoogle,
  cibLinkedin,
  cibSkype,
  cibTwitter,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilChart,
  cilChartPie,
  cilCheck,
  cilChevronLeft,
  cilChevronRight,
  cilCloudDownload,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilDollar,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilFile,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilItalic,
  cilJustifyCenter,
  cilLayers,
  cilList,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMediaPlay,
  cilMenu,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPen,
  cilPencil,
  cilPeople,
  cilPrint,
  cilPuzzle,
  cilReportSlash,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
} from '@coreui/icons';

export const iconSubset = {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibFacebook,
  cibGoogle,
  cibLinkedin,
  cibSkype,
  cibTwitter,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilChart,
  cilChartPie,
  cilCheck,
  cilChevronLeft,
  cilChevronRight,
  cilCloudDownload,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilDollar,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilFile,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilItalic,
  cilJustifyCenter,
  cilLayers,
  cilList,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMediaPlay,
  cilMenu,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPen,
  cilPencil,
  cilPeople,
  cilPrint,
  cilPuzzle,
  cilReportSlash,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
};

// export enum IconSubset {
//   cibCcAmex = 'cibCcAmex',
//   cibCcApplePay = 'cibCcApplePay',
//   cibCcMastercard = 'cibCcMastercard',
//   cibCcPaypal = 'cibCcPaypal',
//   cibCcStripe = 'cibCcStripe',
//   cibCcVisa = 'cibCcVisa',
//   cibFacebook = 'cibFacebook',
//   cibGoogle = 'cibGoogle',
//   cibLinkedin = 'cibLinkedin',
//   cibSkype = 'cibSkype',
//   cibTwitter = 'cibTwitter',
//   cifBr = 'cifBr',
//   cifEs = 'cifEs',
//   cifFr = 'cifFr',
//   cifIn = 'cifIn',
//   cifPl = 'cifPl',
//   cifUs = 'cifUs',
//   cilAlignCenter = 'cilAlignCenter',
//   cilAlignLeft = 'cilAlignLeft',
//   cilAlignRight = 'cilAlignRight',
//   cilApplicationsSettings = 'cilApplicationsSettings',
//   cilArrowBottom = 'cilArrowBottom',
//   cilArrowRight = 'cilArrowRight',
//   cilArrowTop = 'cilArrowTop',
//   cilBasket = 'cilBasket',
//   cilBell = 'cilBell',
//   cilBold = 'cilBold',
//   cilBookmark = 'cilBookmark',
//   cilCalculator = 'cilCalculator',
//   cilCalendar = 'cilCalendar',
//   cilChart = 'cilChart',
//   cilChartPie = 'cilChartPie',
//   cilCheck='cilCheck',
//   cilChevronLeft = 'cilChevronLeft',
//   cilChevronRight = 'cilChevronRight',
//   cilCloudDownload = 'cilCloudDownload',
//   cilCode = 'cilCode',
//   cilCommentSquare = 'cilCommentSquare',
//   cilCreditCard = 'cilCreditCard',
//   cilCursor = 'cilCursor',
//   cilDollar = 'cilDollar',
//   cilDrop = 'cilDrop',
//   cilEnvelopeClosed = 'cilEnvelopeClosed',
//   cilEnvelopeOpen = 'cilEnvelopeOpen',
//   cilFile = 'cilFile',
//   cilHome = 'cilHome',
//   cilInbox = 'cilInbox',
//   cilIndentDecrease = 'cilIndentDecrease',
//   cilIndentIncrease = 'cilIndentIncrease',
//   cilItalic = 'cilItalic',
//   cilJustifyCenter = 'cilJustifyCenter',
//   cilLayers = 'cilLayers',
//   cilList = 'cilList',
//   cilListNumbered = 'cilListNumbered',
//   cilLocationPin = 'cilLocationPin',
//   cilLockLocked = 'cilLockLocked',
//   cilMagnifyingGlass = 'cilMagnifyingGlass',
//   cilMap = 'cilMap',
//   cilMediaPlay = 'cilMediaPlay',
//   cilMenu = 'cilMenu',
//   cilMoon = 'cilMoon',
//   cilNotes = 'cilNotes',
//   cilOptions = 'cilOptions',
//   cilPaperclip = 'cilPaperclip',
//   cilPaperPlane = 'cilPaperPlane',
//   cilPen = 'cilPen',
//   cilPencil = 'cilPencil',
//   cilPeople = 'cilPeople',
//   cilPrint = 'cilPrint',
//   cilPuzzle = 'cilPuzzle',
//   cilReportSlash = 'cilReportSlash',
//   cilSave = 'cilSave',
//   cilSettings = 'cilSettings',
//   cilShare = 'cilShare',
//   cilShareAll = 'cilShareAll',
//   cilShareBoxed = 'cilShareBoxed',
//   cilSpeech = 'cilSpeech',
//   cilSpeedometer = 'cilSpeedometer',
//   cilSpreadsheet = 'cilSpreadsheet',
//   cilStar = 'cilStar',
//   cilSun = 'cilSun',
//   cilTags = 'cilTags',
//   cilTask = 'cilTask',
//   cilTrash = 'cilTrash',
//   cilUnderline = 'cilUnderline',
//   cilUser = 'cilUser',
//   cilUserFemale = 'cilUserFemale',
//   cilUserFollow = 'cilUserFollow',
//   cilUserUnfollow = 'cilUserUnfollow',
// }
