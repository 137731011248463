import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { DirectiveModule } from '../directive/_directive.module';
import { PipesModule } from '../pipes/_pipes.module';

import { DeleteComponent } from './delete/delete.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { PageBannerComponent } from './page-banner/page-banner.component';
import { PreferencesViewComponent } from './preferences-view/preferences-view.component';
import { PrintErrorComponent } from './print-error/print-error.component';
import { ResetBackToModel } from './reset-back-to/reset-back-to-model.component';
import { SvgIconsComponent } from './svg-icons/svg-icons.component';
import { TableIndexingComponent } from './table-indexing/table-indexing.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    DirectiveModule,
  ],
  declarations: [
    SvgIconsComponent,
    DeleteComponent,
    PageBannerComponent,
    TableIndexingComponent,
    MultiselectComponent,
    PreferencesViewComponent,
    ResetBackToModel,
    PrintErrorComponent,
  ],
  exports: [
    TranslateModule,
    SvgIconsComponent,
    DeleteComponent,
    PageBannerComponent,
    TableIndexingComponent,
    MultiselectComponent,
    PreferencesViewComponent,
    PrintErrorComponent,

    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    PipesModule,
    DirectiveModule,
  ],
})
export class SharedComponentsModule {}
