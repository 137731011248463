<form novalidate #modalForm="ngForm" (ngSubmit)="submitModalForm(modalForm)">
  <div class="modal-body">
    <div class="row my-3">
      <p class="m-0 fw-bold">
        All data in target table will be deleted by {{ resetType }} Reset. Do
        you really want to
        <span class="text-decoration-underline">
          reset back to
          {{
            model?.changeTrackingVersion -
              selectedItem?.targetChangeTrackingVersion
          }}
          change tracking version
        </span>
        ?
      </p>
    </div>

    <div class="row">
      <label class="col col-form-label text-end fw-bold text-success"
        >And you're about to reset to</label
      >
      <div
        class="col-4 position-relative text-center"
        (ngInit)="model.changeTrackingVersion = -1"
      >
        <input
          type="number"
          [min]="-1"
          [max]="selectedItem?.targetChangeTrackingVersion - 1"
          id="changeTrackingVersion"
          name="changeTrackingVersion"
          class="form-control rounded"
          placeholder="Enter Tracking Version"
          [(ngModel)]="model.changeTrackingVersion"
          required
        />
        <div
          class="animate__animated animate__fadeInDown animate__delay-1s form-text text-black position-absolute start-0 ms-3"
        >
          Current Change Tracking Version
          <b>{{ selectedItem?.targetChangeTrackingVersion }}</b>
        </div>
      </div>
      <label class="col col-form-label text-start fw-bold text-success"
        >Change Tracking Version</label
      >
    </div>
  </div>

  <div class="modal-footer border-0">
    <button
      type="button"
      class="btn btn-primary btn-sm"
      data-dismiss="modal"
      (click)="activeModal.dismiss('close click')"
    >
      No
    </button>
    <button
      type="submit"
      class="btn btn-danger btn-sm"
      [disabled]="!modalForm.valid || loading"
    >
      Yes
    </button>
  </div>
</form>
