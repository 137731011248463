import {
  QueryFilterMode,
  QueryFilterOperator,
  QueryFilterType,
} from '../classes/Models';

export class ByProperty {
  // @ts-ignore
  FilterMode: QueryFilterMode;
  FilterType: QueryFilterType;
  FilterOperator: QueryFilterOperator;
  PropertyName: string;
  PropertyValue: string;
  PropertyValues: string = '';

  constructor(
    filterMode,
    filterType,
    filterOperator,
    propertyName,
    propertyValue,
    propertyValues?
  ) {
    this.FilterMode = filterMode;
    this.FilterOperator = QueryFilterOperator[ filterOperator as keyof typeof QueryFilterOperator],
    this.FilterType = QueryFilterType[ filterType as keyof typeof QueryFilterType],
    this.PropertyName = propertyName;
    this.PropertyValue = propertyValue;
    this.PropertyValues = propertyValues;
  }
}
[];

export class ByProperties {
  // @ts-ignore
  queryFilters: ByProperty[] = [];
};
