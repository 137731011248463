import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewEncapsulation
} from "@angular/core";
import { ActivationEnd, ActivationStart, Router } from "@angular/router";
import { HeaderComponent } from "@coreui/angular";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService as ToasterService } from "ngx-toastr";
import axios from "axios";

import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

import { BrandingService } from '../../../views/settings/branding/branding.service';

import { OidcAuthService } from "../../../core/oidc/oidc-auth.service";

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultHeaderComponent
  extends HeaderComponent
  implements OnInit, OnDestroy {
  @Input() sidebarId: string = "sidebar";
  loading = false;
  user: any;

  theme$: any;
  theme: any;

  navbarTitle: string = '';

  constructor(
    public brandingService: BrandingService,
    private oidcService: OidcAuthService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private titleService: Title,
    private translateService: TranslateService,
    private toaster: ToasterService,
  ) {
    super();
    router.routeReuseStrategy.shouldReuseRoute = () => false;
    const sub = router.events.subscribe((routeOption) => {
      router.navigated = false;

      if (routeOption instanceof ActivationEnd) {
        if (!this.navbarTitle) {
          this.navbarTitle = routeOption.snapshot.data?.["title"];
        }
      } else if (routeOption instanceof ActivationStart) {
        this.navbarTitle = routeOption.snapshot.data?.["title"];
      }

      this.setChromeTab(this.navbarTitle);

      /*
       * fixed memory leakage due to constantly subscriptions events bcs of routeReuseStrategy
       * */
      sub.unsubscribe();
    });
  }

  setChromeTab(title) {
    if (title) {
      const key = title + "_title";
      const dict = this.translateService.instant(key.toLowerCase());
      this.titleService.setTitle(dict);
    }
  }

  async ngOnInit() {
    this.theme$ = this.brandingService.theme$.subscribe((theme) => {
      this.theme = theme;
    });
    this.user = await this.oidcService.getUser();
  }

  async logout() {
    this.ngxService.start();
    await this.oidcService.logout();
    // await this.oidcService.logoutWithoutRedirect();
    localStorage.clear();
    // this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.theme$?.unsubscribe();
  }
}

