<!--sidebar-->
<c-sidebar #sidebar="cSidebar" class="d-print-none sidebar sidebar-fixed" id="sidebar" visible unfoldable="true">
  <c-sidebar-brand class="py-3" [brandFull]="{
      src: 'assets/img/brand/full.png',
      height: 60,
      alt: 'Logo'
    }" [brandNarrow]="{
      src: 'assets/img/brand/half.png',
      height: 55,
      alt: 'Logo'
    }" routerLink="./">
  </c-sidebar-brand>
  <perfect-scrollbar [config]="perfectScrollbarConfig">
    <!--  [perfectScrollbar]="perfectScrollbarConfig"-->
    <c-sidebar-nav [navItems]="navItems">
    </c-sidebar-nav>
  </perfect-scrollbar>

  <div class="sidebar-toggle-logo-wrapper">

    <div class="side-bar-logo-wrap" toggle="unfoldable" cSidebarToggle="sidebar">
      <img src="assets/img/logo.png">
    </div>

    <c-sidebar-toggler *ngIf="!sidebar.narrow" toggle="unfoldable" cSidebarToggle="sidebar"
      class="bg-transparent"></c-sidebar-toggler>
  </div>
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky"
    sidebarId="sidebar"></app-default-header>

  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="xxl" class="h-auto">
      <router-outlet></router-outlet>
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer></app-default-footer>
</div>
