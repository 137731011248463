import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AxiosInterceptorService } from './axios-interceptor.service';

// Import translation module
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '../_i18n/i18n.service';

import { SharedComponentsModule } from '../components/shared-components.module';

import { LoginSvgComponent } from '../components/login-svg/login-svg.component';
import { LoginComponent } from './oidc/login/login.component';
import { SigningInRedirectComponent } from './oidc/signing-in-redirect/signing-in-redirect.component';
import { SigningOutRedirectComponent } from './oidc/signing-out-redirect/signing-out-redirect.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: I18nService,
      },
      loader: {
        provide: TranslateLoader,
        useClass: I18nService,
      },
    }),
    SharedComponentsModule,
  ],
  declarations: [
    LoginSvgComponent,
    LoginComponent,
    SigningInRedirectComponent,
    SigningOutRedirectComponent,
  ],
  exports: [TranslateModule, SharedComponentsModule],
})
export class CoreModule {
  constructor(
    public axiosIn: AxiosInterceptorService,
    public translateService: TranslateService
  ) {
    if (!this.getDefaultLanguage()) {
      const browserLang: any = this.translateService.getBrowserLang();
      const langCode: any = this.translateService.getBrowserCultureLang();

      const language = {
        id: browserLang,
        name: langCode,
      };

      localStorage.setItem('selectedLanguage', JSON.stringify(language.id));
      localStorage.setItem('language', JSON.stringify(language));
    }
    // this.translateService.setDefaultLang(this.getDefaultLanguage());
    (async () => {
      await this.translateService.use(this.getDefaultLanguage()).toPromise();
    })();
  }

  getDefaultLanguage() {
    const locale = JSON.parse(<any>localStorage.getItem('selectedLanguage'));
    return locale != null ? locale : '';
  }
}
