import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByEnv',
  pure: false,
})
export class FilterByEnv implements PipeTransform {
  transform(list: any[], environment: string): any {
    // if list or environment empty
    if (!list || !environment) return list;

    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return list.filter(
      ({ environment: env }) => env && env.includes(environment)
    );
  }
}
