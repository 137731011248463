import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-banner',
  templateUrl: './page-banner.component.html',
})
export class PageBannerComponent implements OnInit {
  @Input() icon: string | undefined;
  @Input() className: string | undefined;
  @Input() title: string | undefined;
  @Input() back = false;

  constructor() {}

  ngOnInit(): void {}
}
