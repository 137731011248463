import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'print-error',
  templateUrl: './print-error.component.html',
})
export class PrintErrorComponent{
  @Input() control: any;
  @Input() fieldName!: string;
  @Input() errorMessage!: string;
  @Input() isTemplate!: boolean
}
