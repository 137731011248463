import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { OidcAuthService } from '../oidc-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  account: any;

  constructor(
    private oidcService: OidcAuthService,
    private routes: Router,
    private ngxService: NgxUiLoaderService
  ) {
    // not needed login screen (dure)
    this.login();
  }

  ngOnInit(): void {
    this.initialFunctionality();
  }

  initialFunctionality() {}

  login() {
    this.ngxService.start();
    this.oidcService.login();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
