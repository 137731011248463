import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyjson',
  pure: true,
})
export class PrettyJsonPipe implements PipeTransform {
  transform(value: any, args: any[]): any {
    try {
      /**
       * check and try to parse value if it's not an object
       * if it fails to parse which means it is an invalid JSON
       */
      return this.modifyJson(
        typeof value === 'object' ? value : JSON.parse(value),
        args[0],
        args[1]
      );
    } catch (e) {
      return this.modifyJson({ error: 'Invalid JSON' }, args[0], args[1]);
    }
  }

  modifyJson(obj: any, showNumebrLine: boolean = false, padding: number = 4) {
    // line number start from 1
    let line = 1;

    if (typeof obj != 'string') {
      obj = JSON.stringify(obj, undefined, 3);
    }

    /**
     * Converts special charaters like &, <, > to equivalent HTML code of it
     */
    obj = obj
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    /* taken from https://stackoverflow.com/a/7220510 */

    // replace true boolean with fontaweome icon representing true
    obj = obj.replace(
      /(true)/g,
      // class to be applied inside pre tag
      '<i class="fas fa-check-circle green"></i>'
    );

    // replace false boolean with fontaweome icon representing false
    obj = obj.replace(
      /(false)/g,
      // class to be applied inside pre tag
      '<i class="fas fa-exclamation-circle maroon"></i>'
    );
    return obj;
  }
}
