export function CheckJSON(Data: any) {
  try {
    let o = JSON.parse(Data);
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {
    return Data;
  }
}
