<div class="ms-2 slogan-wrapper">
  <div class="slogan" [title]="getTenant?.slogan">
    {{((getTenant?.slogan ? (getTenant?.slogan) : 'Keeping Health') |lowercase|translate) || ''}}
  </div>
  <div class="text">
    {{'all right reserved' |lowercase|translate}}
  </div>
</div>
<div *ngIf="getTenant?.logo" class="ms-auto tenant-wrapper">
  <img alt="Tenant" class="animate__animated animate__headShake" src="{{getTenant?.logo || ''}}">
  <!--<app-svg-icons icon="logo"></app-svg-icons>-->
</div>
