import { EEntityStates, QueryFilterMode, QueryFilterOperator, QueryFilterType } from '../classes/Models';
import { CheckJSON } from '../helper/isJsonObject';
import { environment } from '../../environments/environment';
import { ByProperties, ByProperty } from './byProperty';

let expression = {
  Contains: 'Contains',
  contains: 'Contains',
  '=': 'Equals',
  Equals: 'Equals',
  '<>': 'NotEquals',
  NotEquals: 'NotEquals',
  notcontains: 'NotContains',
  startswith: 'StartsWith',
  SartsWith: 'StartsWith',
  endswith: 'EndsWith',
  EndsWith: 'EndsWith',
  '>=': 'GreaterThanEqualsTo',
  '<=': ' LessThanEqualsTo',
  '>': 'GreaterThan',
  '<': 'LessThan',
  and: 'and',
};

let filters: any = [];

export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export function saveDropdownSelection(key: string, value: any): void {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage(key) {
  return JSON.parse(<any>localStorage.getItem(key));
}

export function setLocalStorage(key, value) {
  saveDropdownSelection(key, value);
}

export function getEntityStateValue(string) {
  let states = EEntityStates;
  let value = capitalizeFirstLetter(string);
  return states[value];
}

export function sortFilters(obj, changeFilters) {
  if (obj?.length === 0) return;

  if (changeFilters) {
    filters = [];
    sortFilters(obj, false);
  }
  if (
    (typeof obj === 'object' && {}.hasOwnProperty.call(obj, 'filterValue')) ||
    (typeof obj === 'object' && obj[4] === 'Or')
  ) {
    if (
      !filters.find((a) => JSON.stringify(a) == JSON.stringify(obj)) ||
      ({}.hasOwnProperty.call(obj, 'filterValue') &&
        filters.some((f) => f[0] !== obj[0]))
    ) {
      filters.push(obj);
    }
  } else if (
    (typeof obj === 'object' && !{}.hasOwnProperty.call(obj, 'filterValue')) ||
    (typeof obj === 'object' && obj[4] !== 'Or')
  ) {
    for (let filter of obj) {
      if (
        typeof filter === 'object' &&
        !filters.find((a) => JSON.stringify(a) === JSON.stringify(filter))
      ) {
        sortFilters(filter, false);
      }
    }
  }

  return filters;
}

export function sortProcessFilters(obj, changeFilters) {
  if (changeFilters) {
    filters = [];
    sortProcessFilters(obj, false);
  }
  if (typeof obj === 'object' && !obj.hasOwnProperty('propertyName')) {
    for (let filter of obj) {
      if (typeof filter === 'object' && filter.hasOwnProperty('propertyName')) {
        if (
          filters?.length > 0 &&
          !checkForSameFilters(
            filters,
            filter.propertyName,
            filter.propertyValue
          )
        ) {
          filters.push(filter);
        } else if (
          filters.length === 0 &&
          filter.propertyName &&
          filter.propertyValue
        ) {
          filters.push(filter);
        }
      } else {
        sortProcessFilters(filter, false);
      }
    }
  }

  filters = filterUnique(filters);
  return filters;
}

export function checkForSameFilters(filters, propertyName, propertyValue) {
  return (
    filters?.find(
      (f) =>
        f.propertyName === propertyName && f.propertyValue === propertyValue
    ) != null
  );
}

export function convertToPropertyNames(obj, changeFilters) {
  if (changeFilters) {
    filters = [];
    convertToPropertyNames(obj, false);
  }

  if (typeof obj === 'object' && !obj.hasOwnProperty('filterValue')) {
    for (let filter of obj) {
      if (
        typeof filter === 'object' &&
        filter.hasOwnProperty('filterValue') &&
        filter[1] !== 'and'
      ) {
        if (filter[2].includes(':')) {
          let splitted = filter[2]?.split(':');
          filter[2] = splitted[1];
        }

        let copy: any = createCopy(filter);
        filters.push(copy);
      } else if (filter[1] === 'and') {
        let value = filter[0][2]?.split('T');
        filter[0][0] = '@timestamp';
        filter[0][1] = 'contains';
        filter[0][2] = value[0];
        filter[0]['filterValue'] = filter[0][2];
        convertToPropertyNames(filter[0], false);
      } else {
        convertToPropertyNames(filter, false);
      }
    }
  }
  if (
    typeof obj === 'object' &&
    obj.hasOwnProperty('filterValue') &&
    obj[1] !== 'and'
  ) {
    let copy: any = createCopy(obj);
    filters.push(copy);
  }

  return filters;
}

export function createCopy(filter) {
  let copy = [];

  copy['propertyName'] = filter[0];
  copy['QueryFilterOperator'] = filter[1];
  copy['propertyValue'] = filter[2];

  return copy;
}

export function sortFilterValues(obj, changeFilters) {
  if (changeFilters) {
    filters = [];
    sortFilterValues(obj, false);
  }

  if (
    typeof obj === 'object' &&
    {}.hasOwnProperty.call(obj, 'filterValue') &&
    obj !== 'and'
  ) {
    filters.push(obj);
  }
  if (typeof obj === 'object' && !{}.hasOwnProperty.call(obj, 'filterValue')) {
    for (let filter of obj) {
      if (typeof filter === 'object') {
        sortFilterValues(filter, false);
      }
    }
  }

  filters = filterUnique(filters);
  return filters;
}

export function uniqueFilter(value, index, self) {
  return self.indexOf(value) === index;
}

export function filterUnique(arr) {
  return arr.filter(uniqueFilter);
}

export function isGUID(uuid) {
  var regexGuid =
    /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
  return regexGuid.test(uuid);
}

export function iterate(obj) {
  let fields: any = [];
  var walked: any = [];
  var stack = [{ obj: obj, stack: '' }];

  while (stack.length > 0) {
    var item: any = stack.pop();
    var obj = item?.obj;
    for (var property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] == 'object') {
          var alreadyFound = false;
          for (var i = 0; i < walked.length; i++) {
            if (walked[i] === obj[property]) {
              alreadyFound = true;
              break;
            }
          }
          if (!alreadyFound) {
            walked.push(obj[property]);
            stack.push({
              obj: obj[property],
              stack: item?.stack + '.' + property,
            });
          }
        } else {
          if (item?.stack == '' || item?.stack == '') {
            fields.push(property);
          } else {
            fields.push(
              (item.stack + '.' + property).replace(/^(\.)/, '').trim()
            );
          }
        }
      }
    }
  }
  const result: any = flatten(fields).sort(
    (one, two) => two.length + one.length
  );
  return result;
}

export function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
}

export function isBigEnough(element, index, array) {
  return element[0] != '_';
}

export function getExternalConnectionToken() {
  let token: any = '';
  for (const key of Object.keys(localStorage)) {
    if (key === 'ExternalConnectionAccessToken') {
      let obj = CheckJSON(localStorage[key]);
      if (
        obj &&
        obj.tokenType &&
        obj.tokenType === 'Bearer' &&
        obj.scopes[0]?.includes(environment.ExternalConnectionAppId)
      ) {
        token = `${obj.tokenType} ${obj.accessToken}`;
      }
    }
  }
  return token;
}

export function getReplicationToken() {
  let token: any = '';
  for (const key of Object.keys(localStorage)) {
    let obj = CheckJSON(localStorage[key]);
    if (obj && obj.tokenType && obj.tokenType === 'Bearer') {
      token = `${obj.tokenType} ${obj.secret}`;
    }
  }
  return token;
}

export function createHeaderFilter(objName, obj) {
  for (let key in obj) {
    let value = obj[key];

    let copy = `"${objName} = '${value.name}'"`;
    obj[key] = { text: value.name, value: copy };
  }
  return obj;
}

export function createHeaderFilterForelasticsearch(objName, obj) {
  for (let key in obj) {
    let value = obj[key];
    let copy = [];

    copy['propertyName'] = objName;
    copy['QueryFilterOperator'] = 'Contains';
    copy['propertyValue'] = value.name;
    copy['filterMode'] = 'And';
    copy['filterType'] = 'Text';

    obj[key] = {
      text: value.name,
      value: value.name,
      filterValue: copy,
    };
  }
  return obj;
}

export function sortList(obj) {
  return obj.sort(
    (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
  );
}

export function getHostsList(hostsDictionary: any) {
  const Keys = ['Subscriber', 'Publisher', 'SchemaReader'];
  let names: any = [];
  for (let key of Keys) {
    if (
      hostsDictionary[key] &&
      hostsDictionary[key]?.length > 0 &&
      hostsDictionary[key] !== undefined
    ) {
      for (let property of hostsDictionary[key]) {
        let obj: any = {};
        obj['name'] = property;
        obj['value'] = property;
        // @ts-ignore
        names?.push(obj);
      }
    }
  }
  return names;
}

export function getHostsHeader(hostlist) {
  let hostnames = getHostsList(hostlist);

  let hostsHeaderFilter = createHeaderFilterForelasticsearch(
    'fields.Hostname',
    hostnames.slice()
  );

  localStorage.setItem(
    'hostnamesHeaderFilters',
    JSON.stringify(hostsHeaderFilter)
  );
  return hostsHeaderFilter;
}

export function getFirstErrorFromResponse(errRes) {
  let firstError = undefined;

  if (!errRes?.error.message.includes('One or more')) {
    firstError = errRes.error.message;
  } else {
    let keys = Object.keys(errRes?.error?.errors);
    if (keys?.length > 0) {
      firstError = errRes.error.errors[keys[0]];
    }
  }
  return firstError;
}

export function createQueryFilterList(
  filters,
  envId?,
  selectedType?,
  applicationSearchTrue?
) {
  let list = new ByProperties();
  for (let filter of filters) {
    let queryFilter = new ByProperty(
      !filter.filterMode ? 'And' : filter.filterMode,
      filter.filterType,
      expression[filter.QueryFilterOperator],
      filter.propertyName,
      filter.propertyValue,
      ''
    );

    let filterExists =
      list.queryFilters.filter(
        (f) =>
          f.PropertyName?.toLowerCase().trim() ===
            queryFilter.PropertyName?.toLowerCase().trim() &&
          f.PropertyValue?.toLowerCase().trim() ===
            queryFilter.PropertyValue?.toLowerCase().trim()
      )?.length > 0;

    if (!filterExists) {
      list.queryFilters.push(queryFilter);
    }
  }

  if (selectedType === 'Environment') {
    let queryFilter = new ByProperty(
      QueryFilterMode.And,
      QueryFilterType.Text,
      QueryFilterOperator.Equals,
      'fields.environmentId',
      envId,
      ''
    );

    list.queryFilters.push(queryFilter);
  } else {
    let queryFilter = new ByProperty(
      QueryFilterMode.And,
      QueryFilterType.Text,
      QueryFilterOperator.Equals,
      'fields.environmentId',
      envId,
      'exists-query'
    );

    list.queryFilters.push(queryFilter);
  }

  if (applicationSearchTrue) {
    let copy = new ByProperty(
      QueryFilterMode.And,
      QueryFilterType.Text,
      QueryFilterOperator.Contains,
      'fields.ApplicationName',
      'replication',
      ''
    );

    list.queryFilters?.push(copy);
  }

  return list.queryFilters;
}
