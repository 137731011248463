import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import theme from './response';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  private renderer: Renderer2;

  private themeObservable = new BehaviorSubject(theme.light);
  public theme$ = this.themeObservable.asObservable();

  private availableThemes = [theme.light, theme.dark];

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  getAvailableThemes() {
    return this.availableThemes;
  }

  getActiveTheme() {
    return this.themeObservable.getValue();
  }

  isDarkTheme(): boolean {
    return this.getActiveTheme().name === theme.dark.name;
  }

  setDarkTheme(): void {
    this.setActiveTheme(theme.dark);
  }

  setLightTheme(): void {
    this.setActiveTheme(theme.light);
  }

  setActiveTheme(theme): void {
    this.themeObservable.next(theme);
    // console.log('theme', this.getActiveTheme());

    // for setting dark/light mode on html attribute
    this.renderer.setAttribute(
      document.documentElement,
      'data-bs-theme',
      this.getActiveTheme().name
    );

    // for creating styles/css
    Object.keys(this.getActiveTheme().properties).forEach((property) => {
      document.documentElement.style.setProperty(
        property,
        this.getActiveTheme().properties[property]
      );
    });
  }
}
