import { Injectable } from "@angular/core";
import { ToastrService as ToasterService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { OidcAuthService } from "../../core/oidc/oidc-auth.service";

import axios from "axios";

@Injectable({
  providedIn: "root"
})
export class IdentityAdminService {
  data: any = [];

  private dataObservable = new BehaviorSubject<any>([]);
  public data$ = this.dataObservable.asObservable();

  constructor(
    private oidcService: OidcAuthService,
    private toaster: ToasterService
  ) {
    if (this.oidcService.getUser()) {
      this.fetchNewUserProfile();
    }
  }

  async init() {
    const selectedPartner = this.getDropdownSelection("selectedPartner");
    const selectedTenant = this.getDropdownSelection("selectedTenant");

    if (!selectedPartner) {
      // if there's no partner then set some default
      const defaultPartner = this.data.partners[0];
      this.updatePartner(defaultPartner);
    }

    if (!selectedTenant) {
      const selectedPartner = this.getDropdownSelection("selectedPartner");
      const partners: any = this.getPartners();

      //  grab tenants of selectedPartner
      const tenants: any = this.getTenants(selectedPartner, partners);
      const isSelectedIndex = tenants.findIndex(x => x.isSelected);

      // to check if any one have isSelected true
      let defaultTenant;
      if (isSelectedIndex === -1) { // -1 indicates item was not found
        defaultTenant = tenants[0];
      } else {
        defaultTenant = tenants[isSelectedIndex];
      }

      let { id } = defaultTenant;
      await this.handleDropdownChange('selectedTenant', id)
    }
  }

  async fetchNewUserProfile(hasLoading = true) {
    let res: any = [];
    try {
      res = await axios.get(`UserProfiles/user-profile/false`, {
        headers: {
          hasLoading
        }
      });
    } catch (e) {
      this.toaster.error(`Request failed: ${e}`);
    }
    this.data = res;
    await this.init(); // initialization of default values
    this.dataObservable.next(this.data);
    return this.data;
  }

  updateData(data) {
    this.data = data;
    this.dataObservable.next(this.data);
  }

  async handleDropdownChange(key: string, value: any) {
    const selectedPartner = this.getDropdownSelection("selectedPartner");
    /*const selectedTenant = this.getDropdownSelection("selectedTenant");
    const selectedBranch = this.getDropdownSelection("selectedBranch");*/

    const partners: any = this.getPartners();
    const tenants: any = this.getTenants(selectedPartner, partners);
    /*const branches = this.getBranches(selectedTenant, tenants);
    const requesters = this.getRequesters(selectedBranch, branches);
    const branchesList: any = this.getBranchesReadModels(selectedTenant, tenants);
    const requestersList = this.getRequesterReadModels(selectedBranch, branches);*/

    switch (key) {
      case "selectedTenant": {
        /*await this.clearDropdownSelection("branch");
        await this.clearDropdownSelection("selectedBranch");
        await this.clearDropdownSelection("selectedBranchReadModel");

        await this.clearDropdownSelection("requester");
        await this.clearDropdownSelection("selectedRequester");
        await this.clearDropdownSelection("selectedRequesterReadModel");*/

        const obj = tenants.find((x) => x.id === value);
        let { id, name, slogan, logo } = obj;
        await this.saveDropdownSelection("tenant", { id, name, slogan, logo });
        await this.saveDropdownSelection("selectedTenant", id);

        this.setDefaultEnvironment(id, tenants);
        break;
      }
      /*case "selectedBranch": {
        await this.clearDropdownSelection("requester");
        await this.clearDropdownSelection("selectedRequester");

        const obj: any = branches.find((x: any) => x.id === value);
        let { id, name } = obj;
        await this.saveDropdownSelection("branch", { id, name });
        await this.saveDropdownSelection("selectedBranchReadModel", branchesList?.filter(b => b?.identification.id == value));

        break;
      }
      case "selectedRequester": {
        const obj: any = requesters.find((x: any) => x.id === value);
        let { id, name, currency } = obj;
        await this.saveDropdownSelection("requester", { id, name, currency });
        const selectedRequesterReadModel = requestersList?.filter((x: any) => x?.account.id == value);
        await this.saveDropdownSelection("selectedRequesterReadModel", selectedRequesterReadModel);

        break;
      }*/
    }

    await this.saveDropdownSelection(key, value);
  }

  missingPreferences() {
    const partner = this.getDropdownSelection("selectedPartner");
    const tenant = this.getDropdownSelection("selectedTenant");
    const environment = this.getDropdownSelection("selectedEnvironment");

    let missingPreferences: any = [];

    !partner && missingPreferences.push("Partner");
    !tenant && missingPreferences.push("Tenant");
    !environment && missingPreferences.push("Environment");

    if (missingPreferences?.length) {
      this.toaster.warning(
        `${missingPreferences.join("<br/>")}`,
        "Missing values",
        {
          enableHtml: true
        }
      );
    }

    return missingPreferences;
  }

  setDefaultEnvironment(selectedTenant, tenants) {
    if (tenants?.length) {
      let obj = tenants.find((x) => x.id === selectedTenant);
      if (obj) {
        let environment = obj["environment"];
        let { id, name } = environment;
        this.saveDropdownSelection("environment", { id, name });
        this.saveDropdownSelection("selectedEnvironment", id);
      }
    }
  }

  getPartners() {
    return this.data?.partners || [];
  }

  updatePartner(partner) {
    let { id , name }  = partner
    this.saveDropdownSelection("partner", { id, name });
    this.saveDropdownSelection("selectedPartner", id);
  }

  getTenants(selectedPartner, partners) {
    let tenants = [];
    if (partners?.length) {
      let obj = partners.find((x) => x.id === selectedPartner);
      if (obj) {
        this.updatePartner(obj); // update current tenant's partner (each time tenant change)
        tenants = obj["tenants"];
      }
    }
    return tenants;
  }

  getBranches(selectedTenant, tenants) {
    let branches = [];
    if (tenants?.length) {
      let obj = tenants.find((x) => x.id === selectedTenant);
      if (obj) {
        branches = obj["branches"];
      }
    }
    return branches;
  }

  getBranchesReadModels(selectedTenant, tenants) {
    let branchesList = [];
    if (tenants?.length) {
      let obj = tenants.find((x) => x.id === selectedTenant);
      if (obj) {
        branchesList = obj["branchesList"];
      }
    }
    return branchesList;
  }

  getRequesters(selectedBranch, branches) {
    let requesters = [];
    if (branches?.length) {
      let obj = branches.find((x) => x.id === selectedBranch);
      if (obj) {
        requesters = obj["requesters"];
      }
    }
    return requesters;
  }

  getRequesterReadModels(selectedBranch, branches) {
    let requestersList = [];
    if (branches?.length) {
      let obj = branches.find((x) => x.id === selectedBranch);
      if (obj) {
        requestersList = obj["requestersList"];
      }
    }
    return requestersList;
  }

  saveDropdownSelection(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getDropdownSelection(key: string): any {
    const savedSelection = localStorage.getItem(key);
    return savedSelection ? JSON.parse(savedSelection) : null;
  }

  clearDropdownSelection(key: string): any {
    localStorage.removeItem(key);
  }
}
