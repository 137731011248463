<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-body pb-0">
    <p class="m-0">{{ message }}</p>
  </div>
  <div class="modal-footer pt-0 border-0">
    <button type="button" class="btn btn-primary btn-sm" (click)="dismiss()">
      No
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="confirmed()">
      Yes
    </button>
  </div>
</ng-template>

<ng-template [ngIf]="textOnly" [ngIfElse]="defaultView">
  <span (click)="confirmation(content)">
    <span class="{{ displayClass }}">{{ title }}</span>
  </span>
</ng-template>
<ng-template #defaultView>
  <button class="btn {{ bClass }} btn-sm" (click)="confirmation(content)">
    <ng-container *ngIf="tooltipText" [title]="tooltipText"></ng-container>
    <i class="{{ iClass }}"></i>
  </button>
</ng-template>
